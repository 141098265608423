import React, { Component } from 'react';

import { Link } from "react-router-dom";

import footerLogo from '../assets/images/footer-logo.png';
// import iconFooterMap from '../assets/images/icon-footer-map.png';

import bbb from '../assets/images/bbb-logo.png';
import guaranteed from '../assets/images/guarantee_logo.png';
import banksAccepted from '../assets/images/banksAccepted.png';
// import appImg from '../assets/images/app.jpg';

class FooterPage extends Component {

	render() {
		return (
			<div>
				<footer>
					<div className="r-footer">
						<div className="container">
							<div className="row clearfix">
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
									<div className="r-footer-block">
										<Link to={"/"}>
											<img src={footerLogo} className="d-block img-fluid" alt=""/>
										</Link>
										<div className="r-footer-widget r-footer-phone">
											<span><i className="fa fa-phone"/>We accept:</span>
											<img src={banksAccepted} className="d-block img-fluid" alt=""/>
										</div>
										<p>
											Need car insurance for a car you will only be driving for a short term? Buy online: Save Money  & Time.
										</p>
									</div>
								</div>

								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
									<div className="r-footer-block">
										<div className="r-footer-widget r-footer-phone">
											<span><i className="fa fa-phone"/>Sales & Support</span>
											<a href={"tel:(956) 621-1644"}><h5>(956) 621-1644</h5></a>
										</div>
										<div className="r-footer-widget r-footer-nav">
											<h6>USEFUL LINK</h6>
											<nav>
												<ul>
													<li><a href="/privacy">Private Policy</a></li>
													<li><a href="/terms">Term & Conditions</a></li>
													<li><a href="/#contact">Contact us</a></li>
													<li><a href="/login">Login to My Account</a></li>
													<li><a href="/login"> Register</a></li>
												</ul>
											</nav>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
									<div className="r-footer-block">
										<div className="r-footer-widget r-footer-phone">
											<span><i className="fa fa-phone"/>Report a Claim</span>
											<a href={"tel:1 (800) 401-9821"}><h5>1 (800) 401-9821</h5></a>
										</div>

										<div className="r-footer-widget r-footer-nav">
											<h6>OUR INFO</h6>
											<nav>
												<ul>
													<li><a href="/#about">About Us</a></li>
													<li><a href="/#how">How it works</a></li>
													<li><a href="/#insurance">Insurance</a></li>
												</ul>
											</nav>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
									{/*<div className="r-footer-widget r-footer-map">
                <a target={"_blank"} href={"https://www.google.com/maps/place/5424+S+Padre+Island+Hwy,+Brownsville,+TX+78521/@25.927112,-97.4561707,17z/data=!3m1!4b1!4m5!3m4!1s0x866f95eeae654cd9:0x48afe0f59a0c5b3e!8m2!3d25.927112!4d-97.453982"}>
                  <img src={iconFooterMap} alt="" className="icon"/> Global Insurance on Map
                </a>
              </div>*/}
									<div className="r-footer-block">
										<img style={{width: 100}} alt="100% Guaranteed" src={guaranteed} />


										{/*<div className="r-footer-widget r-footer-nav">
                  <h6>DOWNLOAD OUR APP</h6>
                   <div className="row row-inputs">
                     <div className="col-sm-12"> <img src={appImg}  alt=""/></div>
                  </div>
                </div>*/}
									</div>
								</div>
							</div>

							<div className="row clearfix r-footer-strip">
								<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
									©2019 Maintenance by: <a href="https://studiomediaagency.com">StudioMedia </a>
								</div>
								<div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
									<ul style={{ width: "100%" }}>
										<li><i className="fa fa-facebook"/>. <span>Facebook</span></li>
										<li><i className="fa fa-twitter"/>.<span>Twitter</span></li>
										<li><i className="fa fa-instagram"/>.<span>Instagram</span></li>
										<li><a href="privacy">Privacy Policy</a></li>
										<li><a href="terms">Terms & Conditions</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		)
	}
}
export default FooterPage;
